// src/main.js
import clipboard from './clipboard.js';
import data from './data.js';
import event from './event.js';
import full from './full.js';
import http from './http.js';
import lang from './lang.js';
import online from './online.js';
import theme from './theme.js';
import type from './type.js';

const openjs = {
    clipboard: clipboard,
    data:data,
    event:event,
    full:full,
    http:http,
    lang:lang,
    online:online,
    theme:theme,
    type: type
}
export {
    clipboard,
    data,
    event,
    full,
    http,
    lang,
    online,
    theme,
    type,
    openjs as default
}