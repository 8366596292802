/**
 * 语言国际化
*/
import app from './core.js'

const utils = app.utils,
		dom = app.dom;

// 模块核心
const langCore = function(options){

	if (!(this instanceof langCore)) {
        return false;
    }
    if (!langCore._instance) {

        // 配置参数
		this.config = {
			lang: '',// 初始化显示的语言类型
			data: {}, // 语言列表
			default: '',// 默认显示的语言类型，如果没有设置lang初始化语言，或者当前设置的语言类型不存在，则返回到默认设置的语言类型，类似404
		}
		// 初始化
		this.init(options);
		// 翻译
		this.translate();

		langCore._instance = this;
    }

    return langCore._instance;
}

// 初始化
langCore.prototype.init = function(options){

	const that = this;
	// 合并参数
	that.config = utils.merge(that.config,options);
	// 设置路由列表
	that.config.data = options.data;

}

/**
 * 修改类型
 * @param type:string 类型名
*/
langCore.prototype.change = function(type){
	const that = this;
	// 设置当前语言类型
	that.config.lang = type;

	// 编译
	that.translate();
}
/**
 * 翻译文本
 * 获取需要修改的文本的元素
 * 转换文本
*/
langCore.prototype.translate = function(){
	const that = this;
	// 获取当前语言数据
	let lang = that.config.data[that.config.lang];
	// 当前语言数据不存在则停止翻译
	if(!lang)return false;
	// 获取所有需要翻译的元素
	let langTemplate = dom.getAll('[open-lang]');
	// 遍历翻译元素
	langTemplate.forEach(item=>{
		// 获取元素属性值
		let attr = dom.attr(item,'open-lang');
		// 分解属性
		let attrSplit = attr.split('.');
 		// 获取属性对应文本
		let text = attrSplit.reduce((acc, cur) => {
			return acc[cur];
		},lang);
		// 更新dom文本
		item.innerText = text;
	})

}	

/**
 * 自动转换
 * 根据页面lang=en值，自动设置语言类型
 * 20240427:下次完成
*/
langCore.prototype.auto = function(){

}

/**
 * 模块、部分翻译
 * 根据id选择器，翻译所有满足条件的子元素
 * 适合组件开发
*/
langCore.prototype.module = function(id,type){

	const that = this;

	let elem = dom.get(id);

	if(!elem)return false;

	// 获取当前语言数据
	let lang = that.config.data[type];


	// 当前语言数据不存在则停止翻译
	if(!lang)return false;

	// 获取所有需要翻译的元素
	let langTemplate = dom.childs(elem,'[open-lang]');
	// 遍历翻译元素
	langTemplate.forEach(item=>{
		// 获取元素属性值
		let attr = dom.attr(item,'open-lang');
		// 分解属性
		let attrSplit = attr.split('.');
 		// 获取属性对应文本
		let text = attrSplit.reduce((acc, cur) => {
			return acc[cur];
		},lang);
		// 更新dom文本
		item.innerText = text;
	})

}


// 模块对象
const lang = {
	name: 'lang',
    version: 'v0.1.0',
    description: '基于JavaScript实现国际化功能模块',
    author: 'liukaijian',
    date: '2024-04-27 21:18'
}

/**
 * 使用
 * @param options.lang:string 初始化设置语言类型
 * @param options.data:object 语言对象
 * @param options.default:string 默认语言类型，兜底的参数
*/
lang.config = function(options){
	new langCore(options);
}

/**
 * 切换语言
 * @param lang: string 语言类型
*/
lang.change = function(lang){
	let instance = new langCore();
	instance.change(lang)
}

/**
 * 局部翻译
 * @param id: string 元素选择器
*/
lang.module = function(id,lang){
	let instance = new langCore();
	instance.module(id,lang)
}



export default lang;