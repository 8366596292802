/**
 * http请求工具库
 */

class http {

    // 插件描述
    static name = "http";
    static author = "kaijian";
    static version = "v1.0.0";
    static description = "基于js开发的http请求插件";
    static date = "2024-06-18";

    // 全局配置参数
    static #config = {
        httpInstance: null,
        mode: '',
        events: {
            before: '',
            after: ''
        }, //全局事件定义
        baseURL: '',
        url: 'https://getman.cn/echo', // 请求URL
        method: 'GET', // 请求的类型：GET,POST,PUT,DELETE,HEAD
        data: {}, // 发送数据
        headers: {},
        async: true, //true(异步)|| false(同步)
        dataType: '',
        timeout: 3000, //超时时间
        before: '',
        after: '',
        success: '',
        fail: ''
    }

    // 全局配置
    static config(options) {

        // 自定义参数
        let defaults = {
            baseURL: '',
            mode: '',
            header: {},
            timeout: 3000
        }

        // 合并自定义参数
        defaults = http.#merge(defaults, options);

        // 合并到配置参数
        http.#config = http.#merge(http.#config, defaults);

    }

    // get请求
    static get(options) {
        // 设置get访问
        options.method = 'GET';
        return http.request(options);
    }

    // post请求
    static post(options) {
        options.method = 'POST';
        return http.request(options);
    }

    // 发送前操作
    static before(fn) {
        // 添加自定义事件
        if (fn && typeof fn === 'function') {
            http.#config.events.before = fn;
        }
    }

    // 响应后操作
    static after(fn) {
        // 添加自定义事件
        if (fn && typeof fn === 'function') {
            http.#config.events.after = fn;
        }
    }

    // 初始化数据
    static #initState(options) {

        // 默认参数
        let defaults = {
            url: '', // 请求URL
            mode: '',
            method: 'GET', // 请求的类型：GET,POST,PUT,DELETE,HEAD
            data: {}, // 发送数据
            headers: {},
            async: true, //true(异步)|| false(同步)
            dataType: '',
            timeout: 0, //超时时间
            before: '',
            after: '',
            success: '',
            fail: ''
        }

        // 合并自定义参数
        defaults = http.#merge(defaults, options);

        // 如果配置全局baseURL,添加到参数里
        if (http.#config.baseURL) {
            defaults.url = http.#config.baseURL + defaults.url;
        }

        // 如果配置全局headers,添加到参数里
        if (Object.keys(http.#config.headers).length > 0) {
            defaults.headers = { ...http.#config.headers, ...defaults.headers };
        }

        // 如果配置全局timeout,添加到参数里
        if (http.#config.timeout !== 3000) {
            defaults.timeout = http.#config.timeout;
        }

        // 如果配置全局mode类型,添加到参数里
        if (http.#config.mode === 'fetch') {
            defaults.mode = 'fetch';
        }

        return defaults;
    }

    // 请求
    static request(options) {

        // 初始化数据
        let configData = http.#initState(options);

        // 判断使用fetch类型
        if (configData.mode == 'fetch') {

        	if(!globalThis.fetch){
        		// 使用默认xmlhttprequest方式
            	return http.#httpRequest(configData);
        	}
            // 使用fetch方式
            return http.#fetchRequest(configData);
        } else {
            // 使用默认xmlhttprequest方式
            return http.#httpRequest(configData);
        }

    }

    // xmlhttprequest请求
    static #httpRequest(options) {

        // 判断是否创建xml对象
        if (http.#config.httpInstance === null) {

            // 初始化ajax
            if (globalThis.XMLHttpRequest) {
                http.#config.httpInstance = new XMLHttpRequest();
            } else {
                http.#config.httpInstance = new ActiveXObject('Microsoft.XMLHttp');
            }
        }

        return new Promise((r, j) => {

            // 初始化请求
            http.#config.httpInstance.open(options.method, options.url, options.async);

            // 设置请求头，默认上传数据json类型
            http.#config.httpInstance.setRequestHeader('Content-Type', 'application/json');

            // 添加自定义请求头

            // 自定义拦截器参数
            let config = {
                async: options.async,
                data: options.data,
                dataType: options.dataType,
                headers: options.headers,
                method: options.method,
                timeout: options.timeout,
                url: options.url,
            }

            // 判断拦截器是否通过
            let isRun = true;

            // 发送前的全局自定义事件，添加请求头或数据拦截，或统一添加数据
            if (http.#config.events.before && typeof http.#config.events.before == 'function') {
                
                let resultData = http.#config.events.before(config);

                if(resultData !== false){
                    if(Object.prototype.toString.call(resultData) === '[object Object]'){
                        options = http.#merge(options,resultData);
                    }
                }else{
                    isRun = false;
                }
            }

            // 发送前的自定义事件，添加请求头或数据拦截，或统一添加数据
            if (isRun && options.before && typeof options.before == 'function') {
                
                let resultData = options.before(config);

                if(resultData !== false){
                    if(Object.prototype.toString.call(resultData) === '[object Object]'){
                        options = http.#merge(options,resultData);
                    }
                }else{
                    isRun = false;
                }
            }

            if(!isRun){
                r({
                    status: 0,
                    message: '未通过before拦截器效验'
                });
                return false;
            }

            // 超时设置
            http.#config.httpInstance.ontimeout = function() {
                // 中止请求
                http.#config.httpInstance.abort();
            };

            http.#config.httpInstance.timeout = options.timeout;

            // 发送请求
            http.#config.httpInstance.send(JSON.stringify(options.data));

            // 响应
            http.#config.httpInstance.onreadystatechange = function() {

                if (http.#config.httpInstance.readyState == 4) {

                    // 返回数据
                    let res = {
                        state: http.#config.httpInstance.readyState,
                        status: http.#config.httpInstance.status,
                        data: http.#config.httpInstance.responseText
                    }

                    // 响应后的全局自定义事件，添加请求头或数据拦截，或统一添加数据
                    if (http.#config.events.after && typeof http.#config.events.after === 'function') {
                        // 执行after事件
                        http.#config.events.after(res);
                    }

                    if (options.after && typeof options.after === 'function') {
                        // 执行after事件
                        options.after(res);
                    }

                    if (http.#config.httpInstance.status == 200) {

                        let contentType = http.#config.httpInstance.getResponseHeader('Content-Type');

                        if (contentType.includes('application/json')) {

                            // 服务器返回的是JSON
                            let responseJson = JSON.parse(http.#config.httpInstance.responseText);

                            if (options.success && typeof options.success === 'function') {
                                options.success(responseJson)
                            }

                            r(responseJson);
                            return false;

                        } else if (contentType.includes('text/html')) {

                            // 服务器返回的是HTML
                            var responseHtml = http.#config.httpInstance.responseText;

                            if (options.success && typeof options.success === 'function') {
                                options.success(responseHtml);
                            }
                            r(responseHtml);
                            return false;
                        }

                    } else {

                        let data = {
                            status: http.#config.httpInstance.status,
                            message: http.#config.httpInstance.statusTest
                        }

                        // 请求出错
                        if (options.fail && typeof options.fail === 'function') {

                            options.fail(data);
                        }

                        j(data);
                    }
                }
            }

        })
    }

    // fetch请求
    static #fetchRequest(options) {

        // 自定义拦截器参数
        let config = {
            async: options.async,
            data: options.data,
            dataType: options.dataType,
            headers: options.headers,
            method: options.method,
            timeout: options.timeout,
            url: options.url,
        }

        // 判断拦截器是否通过
        let isRun = true;

        // 发送前的全局自定义事件，添加请求头或数据拦截，或统一添加数据
        if (http.#config.events.before && typeof http.#config.events.before == 'function') {
            
            let resultData = http.#config.events.before(config);

            if(resultData !== false){
                if(Object.prototype.toString.call(resultData) === '[object Object]'){
                    options = http.#merge(options,resultData);
                }
            }else{
                isRun = false;
            }
        }

        // 发送前的自定义事件，添加请求头或数据拦截，或统一添加数据
        if (isRun && options.before && typeof options.before == 'function') {
            
            let resultData = options.before(config);

            if(resultData !== false){
                if(Object.prototype.toString.call(resultData) === '[object Object]'){
                    options = http.#merge(options,resultData);
                }
            }else{
                isRun = false;
            }
        }

        if(!isRun){
            return Promise.reject({
                status: 0,
                message: 'before拦截器效验未通过'
            });
        }

        return fetch(options.url, {
            method: options.method,
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
            body: Object.keys(options.data).length > 0 ? JSON.stringify(options.data) : null
        }).then(response => {

        	// 发送前的全局自定义事件，添加请求头或数据拦截，或统一添加数据
            if (http.#config.events.after && typeof http.#config.events.after == 'function') {
                http.#config.events.after(response);
            }

            // 发送前的自定义事件，添加请求头或数据拦截，或统一添加数据
            if (options.after && typeof options.after == 'function') {
                options.after(response);
            }

            if (response.ok) {
                return response.json();
            }
            return response.json().then(error => {
                throw new Error(error.message);
            });
        }).catch(error => {

            let res = {
                status: 0,
                message: 'Network response was not ok,' + error
            }
        	return res;
        });
    }


    /**
     * 合并新对象到原对象
     * @param obj: object 原对象
     * @param newObj: object 新的合并对象
     */
    static #merge(obj, newObj) {

        // 缓存实例
        const that = this;

        // 验证是否是object类型
        function typeObject(val) {
            return Object.prototype.toString.call(val) === '[object Object]';
        }

        // 验证是否是array类型
        function typeArray(val) {
            return Array.isArray(val);
        }

        // 如果参数类型不对,返回对象
        if (!typeObject(obj) || !typeObject(newObj)) return {};

        // 如果obj存在，newObj不存在，返回obj
        if (typeObject(obj) && !typeObject(newObj)) return obj;

        // 合并数组
        function mergeArray(arr, newArr) {

            // 数据缓存
            let arrData = [];

            arr.forEach((item, index) => {
                if (newArr[i]) {
                    arrData.push(newArr[i])
                } else {
                    arrData.push(item);
                }
            })
            return arrData;
        }

        // 合并对象
        function mergeObject(obj, newObj) {

            // 遍历源对象
            for (let key in obj) {

                // 判断目标对象是否有当前key值
                if (newObj.hasOwnProperty(key)) {

                    // 合并对象值
                    if (typeObject(obj[key])) {
                        obj[key] = newObj[key];

                        // 合并数组值
                    } else if (typeArray(obj[key])) {
                        obj[key] = newObj[key];

                        // 合并文本值
                    } else {
                        obj[key] = newObj[key];
                    }
                }
            }
            // 返回值
            return obj;
        }
        return mergeObject(obj, newObj);
    }
}

export default http;