
// dom操作
const dom = {
	/**
	 * 获取单个dom元素
	 * @param elem: String css选择器
	 * @return HTMLElement 返回找到的dom对象
	*/
	get: function(elem){
		return document.querySelector(elem)
	},
	/**
	 * 获取元素文本
	 * @param elem: String css选择器
	 * @return HTMLElement 返回找到的dom对象
	*/
	text: function(elem){
		return document.querySelector(elem).innerText;
	},
	/**
	 * 获取批量dom元素集
	 * @param elem: String css选择器
	 * @return HTMLElement 返回找到的dom对象集
	*/
	getAll: function(elem){
		return document.querySelectorAll(elem);
	},
	/**
	 * 设置或获取元素属性值
	 * @param elem: HTMLElement 元素对象
	 * @param attr: String 属性名
	 * @param val: String 属性值
	 * @return String 返回获取的属性值
	*/
	attr: function(elem,attr,val){

		// 如果值存在，则设置值
		if(val && typeof val === 'string'){

			// 设置元素属性值
			elem.setAttribute(attr, val);

			return false;
		}

		// 返回获取的属性值
		return elem.getAttribute(attr);
	},
	/**
	 * 获取父元素
	 * @param elem: HTMLElement 元素对象
	 * @return HTMLElement 返回dom元素的父元素
	*/
	parent: function(elem){
		return elem.parentNode;
	},
	// 获取上一个兄弟元素
	prev: function(elem){
		return elem.previousElementSibling;
	},
	// 获取下一个兄弟元素
	next: function(elem){
		return elem.nextElementSibling;
	},
	/**
	 * 获取单个子元素
	 * @param elem: HTMLElement 元素对象
	 * @param child: String 子元素的选择器
	 * @return HTMLElement 返回查询到的dom元素
	*/
	child: function(elem,child){
		return elem.querySelector(child);
	},
	/**
	 * 获取子元素集合
	 * @param elem: HTMLElement 元素对象
	 * @param child: String 子元素的选择器
	 * @return HTMLElement 返回查询到的dom元素集
	*/
	childs: function(elem,child){
		return elem.querySelectorAll(child);
	},
	/**
	 * 设置class名
	 * @param elem: HTMLElement 元素对象
	 * @param className: String class名
	*/
	setClass: function(elem,className){
		elem.className = className;
	},
	/**
	 * 获取class名
	 * @param elem: HTMLElement 元素对象
	 * @return String 返回查询到class名
	*/
	getClass: function(elem){
		return elem.classList.toString();
	},
	/**
	 * 添加class名
	 * @param elem: HTMLElement 元素对象
	*/
	addClass: function(elem,className){
		elem.classList.add(className);
	},
	/**
	 * 移除class名
	 * @param elem: HTMLElement 元素对象
	*/
	removeClass: function(elem,className){
		elem.classList.remove(className);
	},
	/**
	 * 创建dom
	 * @param elem: string 元素名
	 * @return String 返回创建的dom
	*/
	create: function(elem){
		let dom = document.createElement(elem);
		return dom;
	},
	/**
	 * 添加dom到当前dom的后面
	 * @param elem: HTMLElemnt 当前dom对象
	 * @param afterDom: HTMLElemnt 添加的dom
	*/
	addAfter: function(elem,afterDom){
		elem.insertAdjacentHTML('afterend',afterDom);
	},
	/**
	 * 添加dom到当前dom的前面
	 * @param elem: HTMLElemnt 当前dom对象
	 * @param afterDom: HTMLElemnt 添加的dom
	*/
	addBefore: function(elem,dom){
		elem.insertAdjacentHTML('beforebegin',dom);
	},
	/**
	 * 添加到第一个子元素
	 * @param elem: HTMLElemnt 当前dom对象
	 * @param child: HTMLElemnt 添加的子元素dom
	*/
	addFirstChild: function(elem,child){
		elem.insertAdjacentHTML('afterbegin',child);
	},
	/**
	 * 添加子元素
	 * @param elem: HTMLElemnt 当前dom对象
	 * @param child: HTMLElemnt 添加的子元素dom
	*/
	addChild: function(elem,child){
		elem.insertAdjacentHTML('beforeend',child);
	},
	/**
	 * 删除子元素
	 * @param elem: HTMLElemnt 当前dom对象
	 * @param child: HTMLElemnt 删除的子元素dom
	*/
	removeChild: function(elem,child){
		elem.removeChild(child);
	},
	/**
	 * 隐藏元素
	 * @param elem: HTMLElemnt 当前dom对象
	*/
	hide: function(elem){
		elem.style.display = 'none';
	}
}

// 工具方
const utils = {
	/**
	 * 监听循环结束，执行事件
	 * @param index: Number 当前循环下标
	 * @param arr: Array 当前循环的数组
	 * @param fn: Function 回调函数
	*/
	onLoopEnd: function(index,arr,fn){
		if(index == arr.length - 1 && fn && typeof fn == 'function'){
			fn();
		}
	},
	/**
	 * 根据时间生成随机id
	 * @return string 返回生成的随机id
	*/
	randomId: function() {
	  	const randomId = Date.now().toString(36) + Math.random().toString(36).substring(2);
	  	return randomId;
	},
	/**
	 * 格式化id
	 * 返回id
	*/
	formatId: function(id){

		// 如果有#去除
		id = id.replace(/#/g,'');

        // 获取组件自动生成id
        return `#${id}`;
	},
	/**
	 * 格式化class
	 * 返回id
	*/
	formatClass: function(className){

		// 如果有#去除
		className = className.replace(/./g,'');

        // 获取组件自动生成id
        return `.${className}`;
	},
	/**
	 * 格式化px
	 * 返回像素距离
	*/
	formatPx: function(pxName){

		// 如果有#去除
		pxName = pxName.replace(/px/g,'');

        // 获取组件自动生成id
        return `${pxName}px`;
	},
	/**
	 * 查找元素是否包含指定类名
	 * @param elem: HTMLElement 当前元素
	 * @param className: String 查找的class名
	 * @return boolean 返回查找结果
	*/
	existClass: function(elem,className){
	    return elem.classList.contains(className);
	},
	/**
	 * 查找字符串中是否存在指定字符
	 * @param str: String 原始字符
	 * @param char: String 查找的字符
	 * @return boolean 返回查找结果
	*/
	existString: function(str,char){
	    return str.indexOf(char) != -1;
	},
	// 防抖节流
	throttle: function(fn,time){

		if(!fn || typeof fn !== 'function') return false;
		
		let timer = null;
	    clearTimeout(timer);
	    timer = setTimeout(fn,time)
		
	},
	// 获取内容视口宽度
	getInnerWidth: function(){
		if(globalThis){
			return globalThis.innerWidth;
		}
	},
	/**
	 * 合并2个对象，返回新的对象值
	 * @param obj:object 对象
	 * @param newObj:object 对象
	 * @return obj:object 返回合并后的新对象
	*/
	merge: function(obj={},newObj={}){

		// 如果参数类型不对,返回对象
		if(!typeObject(obj) || !typeObject(newObj))return {};

		// 如果obj存在，newObj不存在，返回obj
		if(typeObject(obj) && !typeObject(newObj))return obj;

		// 验证是否是object类型
		function typeObject(val){
			return Object.prototype.toString.call(val) === '[object Object]';
		}

		// 验证是否是array类型
		function typeArray(val){
			return Array.isArray(val);
		}

		// 合并数组
		function mergeArray(arr,newArr){

			let arrData = [];

			arr.forEach((item,i)=>{
				if(newArr[i]){
					arrData.push(newArr[i])
				}else{
					arrData.push(item);
				}
			})
			return arrData;
		}

		// 合并对象
		function mergeObject(obj,newObj){

			// 遍历源对象
			for(let key in obj){

				// 判断目标对象是否有当前key值
				if(newObj.hasOwnProperty(key)){

					// 合并对象值
					if(typeObject(obj[key])){

						obj[key] = mergeObject(obj[key],newObj[key]);

					// 合并数组值
					}else if(typeArray(obj[key])){

						obj[key] = newObj[key];
						// obj[key] = mergeArray(obj[key],newObj[key]);

					// 合并文本值
					}else{
						obj[key] = newObj[key];
					}
				}
			}
			// 返回值
			return obj;
		}
			  
		return mergeObject(obj,newObj);
	},
	// 合并数组并去重
	mergeArr: function(arr1, arr2) {
	  	const mergedArray = [...arr1, ...arr2];
	  	const dedupedArray = mergedArray.reduce((acc, currentValue) => {
	    	if (!acc.includes(currentValue)) {
	      		acc.push(currentValue);
	    	}
	    	return acc;
	  	}, []);
	  	return dedupedArray;
	},
	// 模版引擎
	template: function(template,data){

	    const getKeyValue = (key) => {

	        keyArr = key.split('.');

	        let value; 

	        keyArr.forEach(item=>{

	            if(keyArr.length > 1){
	                value = data[keyArr.shift()][keyArr.pop()]
	            }else{
	                value = data[keyArr.shift()]
	            }

	        })

	        return value;
	    }

	    let rex = /\{\{([^${}]+)\}\}/g;

	    return template.replace(rex, function(match, key) {
	        return getKeyValue(key);
	    });
	}
}

// 监听页面加载事件
const onload = function(fn){
	if(fn && typeof fn === 'function'){

		if(globalThis){
			globalThis.addEventListener('load',fn());
		}
	}
}

// 刷新页面
const reload = function(){
	if(globalThis){
		globalThis.location.reload();

	}
}

export {
	dom,
	utils,
	onload,
	reload,
}

const app = {
	dom: dom,
	utils: utils,
	onload: onload,
	reload: reload,
}

export default app;