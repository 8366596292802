/**
 * 本地数据存储
*/

class dataer{

	// 插件描述
	static name = "data";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的localStorage本地数据存储";
	static date = "2024-07-04";


	/** 
	 * 设置数据
	 *  @key: 健值
	 *  @val: 值
	 *  @click: 回调函数
	 *  @exp: 过期时间
	 *  @expType: 过期时间类型
	 */
	static set(options){
	    let instance = dataer.#create();
	    instance.#set(options);
	}

	/** 
	 * 获取数据
	 *  @key: 健值
	 *  @val: 值
	 *  @exp: 过期时间
	 */
	static get(options){
	    let instance = dataer.#create();
	    instance.#get(options);
	}

	/** 
	 * 删除数据
	 *  @key: 健值
	 *  @val: 值
	 *  @exp: 过期时间
	 */
	static remove(options){
	    let instance = dataer.#create();
	    instance.#remove(options);
	}

	/** 
	 * 删除全部数据
	 *  @key: 健值
	 *  @val: 值
	 *  @exp: 过期时间
	 */
	static removeAll(callback){
	    let instance = dataer.#create();
	    instance.#removeAll(callback);
	}


	// 创建实例
	static #create(){
		// 判断当前环境是否支持localStorage
	    if(!globalThis.localStorage){
	        console.error("open-data:当前浏览器不支持localStorage");
	        return false;
	    }

	    return new dataer();
	}

	// 构造函数
	constructor(){

		// 单例模式
	    if(!dataer._instance) {

	        dataer._instance = this
	    }

	    return dataer._instance
	}

	/** 
	 * 设置数据
	 *  @key: 健值
	 *  @val: 值
	 *  @exp: 过期时间
	 *  @fn: 回调函数
	 *  @expType: 时间类型
	 */
	#set(options){

	    // 判断参数
	    if(!options.key || !options.value){
	        console.error('open-data:缺少参数key或val');
	        return false;
	    }

	    const that = this;

	    // 参数提示
	    // let config = {
	    // 	key: '',
	    // 	value: '',
	    // 	type: 'localstorage',
	    // 	encrypt: false,
	    // }

	    // 回调信息
	    let result = {
	        status: false,
	        message: '保存失败',
	    }

	    // 数据
	    let data = {};

	    // 时间类型
	    let timeType = {
	        s: 1000,
	        h: 1000 * 3600,
	        d:  1000 * 3600 * 24,
	    }


	    // 判断是否设置过期时间
	    if(options.exp && typeof options.exp == 'number' && options.exp >= 1){

	        // 默认时间类型为秒
	        let exp = options.exp * timeType.s;

	        // 如果有设置时间类型
	        if(timeType[options.expType]){

	            exp = options.exp * timeType[options.expType];
	        }
	        data = {
	            val: options.value,
	            exp: exp,
	            time: new Date().getTime(),
	        };
	    }else{
	        data = {
	            value: options.value,
	        };
	    }

	    if(globalThis){

	    	 // 保存数据
	   		 globalThis.localStorage.setItem(options.key,JSON.stringify(data));
	    }

	    // 获取数据
	    let res = that.#hasValue(options.key);

	    // 判断是否保存成功
	    if(res){
	        result.status = true;
	        result.message = '保存成功';
	    }

	    // 回调函数
	    options.click && typeof options.click === 'function' && options.click(result);
	}

	/** 
	 * 获取数据
	 *  @options.key: 健值
	 *  @options.click: 回调函数
	 */
	#get(options){

	    if(!options.key){
	        console.error('open-data:缺少参数key');
	        return false;
	    }

	    const that = this;

	    let result = {
	        status: false,
	        message: '未获取到数据',
	        data: null
	    }

	    // 获取当前时间
	    let nowTime = new Date().getTime();

	    let res = '';

	    if(globalThis){
	    	res = globalThis.localStorage.getItem(options.key)
	    }

	    let data = JSON.parse(res);

	    if(data){

	        // 判断是否设置过期时间
	        if(data.exp){

	            if(nowTime - data.time > data.exp ){
	                result.message = '数据已过期';
	                that.#remove({key:options.key});
	            }else{
	                result.status = true;
	                result.message = '获取成功';
	                result.data = data.value;
	            }
	        }else{
	            result.status = true;
	            result.message = '获取成功';
	            result.data = data.value;
	        }

	        // 回调函数
	        options.click && typeof options.click === 'function' &&  options.click(result);
	    }else{
	        // 回调函数
	        options.click && typeof options.click === 'function' && options.click(result);
	    }
	}

	/** 
	 * 删除数据
	 *  @options.key: 健值
	 *  @options.click: 回调函数
	 */
	#remove(options){

	    const that = this;

	    if(!options.key){
	        console.error('open-data:缺少参数key');
	        return false;
	    }

	    let result = {
	        status: false,
	        message: '删除失败',
	    }

	    if(globalThis){
	    	globalThis.localStorage.removeItem(options.key);
	    }

	    let res = that.#hasValue(options.key);

	    if(!res){
	        result.status = true;
	        result.message = '删除成功';
	    }

	    // 回调函数
	    options.click && typeof options.click === 'function' && options.click(result);
	}

	/** 
	 * 删除全部数据
	 *  @key: 健值
	 */
	#removeAll(callback){

	    let result = {
	        status: false,
	        message: '删除失败'
	    }

	    let res = '';

	    if(globalThis){
	    	res = globalThis.localStorage.clear()
	    }

	    let length = '';

	    if(globalThis){
	    	length = globalThis.localStorage.length
	    }

	    if(length == 0){
	        result.status = true;
	        result.message = '删除成功';
	    }

	    // 回调函数
	    callback && typeof callback === 'function' && callback(result);
	}

	/**
	 * 判断值是否存在
	*/
	#hasValue(key){

	    // 获取值
	    let result = '';

	    if(globalThis){
	    	result = globalThis.localStorage.getItem(key)
	    }

	    // 判断值
	    if(result === null){

	        return false;
	    }else{

	        return true;
	    }
	}
}


export default dataer;