/**
 * js类型验证
*/
class typer{

	/**
	 * 验证字符串
	 * @param val: string 验证的字符串文本
	 * @return boolean 返回验证结果
	*/
	static string(val){
		return typeof val === "string";
	}
	// hook
	static str(val){
		return typer.string(val);
	}

	/**
	 * 验证number
	 * @param val: string 验证的字符串文本
	 * @return boolean 返回验证结果
	*/
	static number(val){
		return typeof val === "number";
	}
	// hook
	static num(val){
		return typer.number(val);
	}

	/**
	 * 验证boolean
	 * @param val: string 验证的字符串文本
	 * @return boolean 返回验证结果
	*/
	static boolean(val){
		return typeof val === "boolean";
	}
	// hook
	static bool(val){
		return typer.boolean(val);
	}

	/**
	 * 验证数组
	 * @param val: string 验证的字符串文本
	 * @return boolean 返回验证结果
	*/
	static array(val){
		return Array.isArray(val) || val instanceof Array;
	}
	// hook
	static arr(val){
		return typer.array(val);
	}

	/**
	 * 验证对象
	 * @param val: string 验证的字符串文本
	 * @return boolean 返回验证结果
	*/
	static object(val){
		return Object.prototype.toString.call(val) === "[object Object]";
	}
	// hook
	static obj(val){
		return typer.object(val);
	}

	/**
	 * 验证函数
	 * @param val: string 验证的字符串文本
	 * @return boolean 返回验证结果
	*/
	static function(val){
		return typeof val === "function";
	}
	// hook
	static fn(val){
		return typer.function(val);
	}

	/**
	 * 验证变量值是否存在
	 * @param val: string 验证的字符串文本
	 * @return boolean 返回验证结果
	*/
	static value(val){
		return value !== null && value !== undefined && value !== '';
	}
	// hook
	static val(val){
		return typer.value(val);
	}

	/**
	 * 验证两个对象是否一样
	 * @param val: string 验证的字符串文本
	 * @return boolean 返回验证结果
	*/
	static isObjectEqual(val,oldVal){
		return JSON.stringify(val) == JSON.stringify(oldVal);
	}
	// hook
	static ioe(val){
		return typer.areObjectEqual(val);
	}

	// 验证多纬数据类型
	static type(){

	}
}

export default typer;