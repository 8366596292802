/**
 * 复制内容到剪切板
*/
import { dom,utils } from './core.js'

class clipboarder{

	// 插件描述
	static name = "clipboard";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的clipboard剪贴板工具";
	static date = "2024-07-04";

	// 文本模式
	static text(text,fn){

		if(!text) return false;

	    let options = {
	        text: text
	    }

	    let instance = clipboarder.#create(options);

	    // 文本复制模式
	    return instance.#text(fn);
	}

	// 按钮模式
	static btn(fn){

	    let instance = clipboarder.#create();

	    // 按钮复制模式
	    return instance.#btn(fn);
	}

	/**
	* 创建实例
	* @param options:object 参数
	* @param elem: object 静态组件dom对象
	*/
	static #create(options={}){
	    return new clipboarder(options);
	}

	// 构造函数
	constructor(options){

		const that = this;

		// 配置参数
	    that.config = {
	        result: true,
	        text: '',
	    }

	    // 合并配置参数
		that.config = utils.merge(that.config,options);

	}


	// 文本复制
	#text(fn){

		const that = this;

		return new Promise((r,j)=>{

		    if(globalThis.navigator.clipboard){
		        that.#clipboard(that.config.text);
		    }else{
		        that.#execcommand(that.config.text);
		    }

		    let result = {
		    	status: that.config.result,
		        data: that.config.result ? that.config.text : ''
		    }

		    if(fn && typeof fn === 'function' ){
		        fn(result);
		    }

		    r(result);
		})
	}

	// 按钮复制
	#btn(fn){

	    const that = this;

	    return new Promise((r,j)=>{

	    	// 获取所有clip元素
		    let openClipList = document.querySelectorAll('[open-clip-text]');

		    // 如果没有元素则不执行
		    if(openClipList.length < 1) return false;

		    // 遍历数组
		    openClipList.forEach(function(item){

		        item.onclick = function(){

		            that.config.text = item.getAttribute('open-clip-text');

		            if(globalThis.navigator.clipboard){

		                that.#clipboard(that.config.text);
		            }else{  
		                that.#execcommand(that.config.text);
		            }

		            let result = {
	                    status: that.config.result,
	                    data: that.config.result ? that.config.text : ''
	                };

		            if(fn && typeof fn === 'function'){
		                fn(result)
		            }

		            r(result);
		        }
		    })
	    })
	    
	}

	/**
	 * clipboard 方法
	*/
	#clipboard(text){

	    const that = this;

	    if(globalThis){
	    	 // 复制
		    globalThis.navigator.clipboard.writeText(text).then(function(res){

		        that.config.result = true;

		    }).catch((res)=>{

		        let result = that.#execcommand(text);

		        if(result){
		            that.config.result = true;
		        }else{
		            that.config.result = false;
		        }
		    })
	    }
	}

	/**
	 * document.execCommand 方法
	*/
	#execcommand(text){

	    const that = this;

	    // 创建input元素
	    let inputDom = document.createElement('textarea');
	    inputDom.style.background = 'red';
	    inputDom.style.position = "absolute";
	    inputDom.style.opacity = 0;
	    inputDom.style.left = "-999999px";
	    inputDom.style.top = "-999999px";

	    // 设置为只读 防止移动端手机上弹出软键盘  
	    inputDom.setAttribute('readonly', 'readonly');

	    // 给input元素赋值
	    inputDom.value = text;

	    // 将创建的input添加到body
	    document.body.appendChild(inputDom);

	    // 选中input元素的内容
	    inputDom.select();

	    let copyResult = document.execCommand('copy')

	    // 判断是否复制成功
	    if (copyResult) {

	        that.config.result = true;
	    } else {

	        that.config.result = false;

	    }

	    document.body.removeChild(inputDom);
	}

}

export default clipboarder;