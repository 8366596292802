/**
 * 主题切换
*/
import { utils } from './core.js'

class theme{

	// 插件描述
	static name = "theme";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的主题切换组件";
	static date = "2024-07-04";

	// 缓存实例
	static #instance;

	// 初始化
	static init(options){

	    if(!options)return false;

	    // 创建实例
	    return theme.#create(options);

	}

	// 切换主题
	static toggle(themeName){

		// 创建获取实例
	    let instance = theme.#create();

	    // 切换主题
	    instance.#toggle(themeName);
	}

	// 获取当前主题
	static getCurrentTheme(){

		// 创建获取实例
	    let instance = theme.#create();

	    // 切换主题
	    return instance.config.currentTheme;
	}

	// 创建实例
	static #create(options){
		return new theme(options);
	}

	// 构造函数
	constructor(options){

		if (!theme.#instance) {

	        // 配置参数
	        this.config = {
	        	default: '',
	        	currentTheme: '',
	            data: [],//存储主题列表
	        }

	        // 初始化数据
	        this.#init(options);
	        
	        theme.#instance = this;
	    }

	    return theme.#instance;
	}

	// 初始化
	#init(options){

		const that = this;

		// 合并参数
	    that.config = utils.merge(options);

	    // 初始化主题类型
	    if(that.config.default){
	    	that.config.currentTheme = that.config.default;
	    	if(typeof window !== 'undefined'){
	    		document.querySelector('html').setAttribute('open-theme',that.config.default);
	    	}
	    }
	}

	// 切换主题
	#toggle(theme){

	    const that = this;

	    // 如果主题类型存在
	    if (theme && that.config.data.includes(theme)) {

	    	that.config.currentTheme = theme;

	    	if(typeof window !== 'undefined'){
	    		// 切换主题
	        	document.querySelector('html').setAttribute('open-theme',theme);
	    	}

	    // 如果theme,则默认清除主题属性值，恢复默认样式
	    } else {
	    	// 如果设置了默认类型
	    	if(that.config.default){
	    		that.config.currentTheme = that.config.default;
	    		if(typeof window !== 'undefined'){
		    		// 切换主题
		        	document.querySelector('html').setAttribute('open-theme', that.config.default);
		    	}
	    	}else{
	    		that.config.currentTheme = '';
	    		if(typeof window !== 'undefined'){
	    			document.querySelector('html').setAttribute('open-theme', '');
	    		}	
	    	}
	    }

	}

}

export default theme;