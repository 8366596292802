/**
 * 全屏工具
*/
class full{

	// 插件描述
	static name = "full";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的全屏工具";
	static date = "2024-07-04";


	// 向指定元素绑定点击事件，切换全屏
	static btn = function(id){

		let btnElem = document.querySelector(id);

		if(!id || !btnElem)return false;

		full.#create(id);

	}

	// 切换全屏
	static toggle = function(){

		let instance = full.#create();

		instance.#onToggle();
	}

	// 创建实例
	static #create(id){
		return new full(id);
	}

	// 构造函数
	constructor(id){

		// 默认配置参数
		this.config = {
			id: '',
			elem: ''
		}

		if(id){
			this.#init(id);
			this.#onClick();
		}
	}

	// 初始化数据
	#init(id){

		const that = this;

		// 合并参数
		that.config.id = id;

		// 获取id元素
		that.config.elem = document.querySelector(that.config.id);
	}

	// 添加点击事件
	#onClick(){

		const that = this;

		if(!that.config.elem)return false;

		// 添加事件
		that.config.elem.onclick = function(){
			// 切换全屏
			that.#onToggle();
		}
	}

	// 切换全屏
	#onToggle(){

		const that = this;

		// 如果是全屏
		if(that.#isFullScreen()){
			// 退出全屏
			that.#exit();
		}else{
			// 进入全屏
			that.#open();
		}
	}
	// 进入全屏
	#open(){
		/* 获取(<html>)元素以全屏显示页面 */
	    const full = document.querySelector('body');
	    if (full.RequestFullScreen) {
	        full.RequestFullScreen()
	        //兼容Firefox
	    } else if (full.mozRequestFullScreen) {
	        full.mozRequestFullScreen()
	        //兼容Chrome, Safari and Opera等
	    } else if (full.webkitRequestFullScreen) {
	        full.webkitRequestFullScreen()
	        //兼容IE/Edge
	    } else if (full.msRequestFullscreen) {
	        full.msRequestFullscreen()
	    }
	}
	// 退出全屏
	#exit(){
	    if(document.exitFullScreen) {
	        document.exitFullScreen();
	    //兼容Firefox
	    } else if(document.mozCancelFullScreen) {
	        document.mozCancelFullScreen();
	    //兼容Chrome, Safari and Opera等
	    } else if(document.webkitExitFullscreen) {
	        document.webkitExitFullscreen();
	    //兼容IE/Edge
	    } else if(element.msExitFullscreen) {
	        element.msExitFullscreen();
	    }
	}

	// 判断当前页面是不是全屏
	#isFullScreen() {
	    return !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement);
	}
}

export default full;