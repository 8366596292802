/**
 * 监听获取网络状态
*/

const lineCore = function(){

	if (!(this instanceof lineCore)) {
        return false;
    }
    // 单例模式
    if (!lineCore._instance) {

        this.config = {
			callback: ''
		}

		this.init();

        lineCore._instance = this
    }

    return lineCore._instance
}

lineCore.prototype.init = function(){

	const that = this;
	that.on();
	that.off();
	that.onChange();
}

// 添加online事件监听
lineCore.prototype.on = function(){
	const that = this;

	if (typeof window !== "undefined"){
		window.addEventListener('online',this.onUpdate.bind(that));
	}
}

// 添加change事件监听:没有写完，判断网速变差
lineCore.prototype.onChange = function(){
	const that = this;

	let connection = '';

	if(typeof window !== 'undefined'){

		connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection

		connection.addEventListener('change',function(res){
			if(connection.effectiveType == '2g'){
				if(that.config.callback && typeof that.config.callback === 'function'){
					that.config.callback('2g');
				}
			}
		})
	}
}

// 添加offline事件监听
lineCore.prototype.off = function(){
	const that = this;

	if (typeof window !== "undefined"){
		window.addEventListener('offline',this.onUpdate.bind(that));
	
	}
}

// 根据网络状态，返回结果
lineCore.prototype.onUpdate = function(res){
	const that = this;
	let result = false;
	if(res.type === 'online'){
		result = true;
	}
	if(that.config.callback && typeof that.config.callback === 'function'){
		that.config.callback(result);
	}
}

// 移除事件监听
lineCore.prototype.remove = function(){
	const that = this;

	if (typeof window !== "undefined"){
		window.unonload = function(){
			window.removeEventListener('online',that.update);
			window.removeEventListener('offline',that.update);
		}
	}
}

// 实时监听网络状态
lineCore.prototype.watch = function(fn){
	this.config.callback = fn;
}

// 获取网络状态
lineCore.prototype.get = function(){
	if (typeof window !== "undefined" && window.navigator.onLine) {
       	return true
    } else {
        return false;
   	}
}

const online = {
	name: 'openOnLine',
	version:'v1.1.0',
	author: 'liukaijian',
	date: '2024-04-21 16:11',
	description: "浏览器网络状态监听"
}

// 监听网络状态
online.watch = function(fn){
	let instance = new lineCore();
	instance.watch(fn);
}

// 返回网络状态
online.get = function(){
	let instance = new lineCore();
	return instance.get();
}

export default online;